.select {
  width: 300px;
  .select__control {
    background: #262c33;
    border: 0;
    padding: 1px 0;
    font-variation-settings: 'wght' 500;
  }
  .select__single-value {
    color: #ffffff;
  }
  .select__placeholder {
    font-variation-settings: 'wght' 500;
    color: #ffffff;
  }
  .select__indicator {
    padding: 0 8px;
    color: #ffffff;
  }
  .select__option {
    color: #54545a;
    font-variation-settings: 'wght' 500;
  }
  .select__value {
    background: peachpuff;
  }
  .select__input {
    color: #ffffff;
  }
}
