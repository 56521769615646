.app {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  min-height: 100vh;
}

@media only screen and (max-width: 768px) {
  .app {
    margin-left: 0;
  }
}
