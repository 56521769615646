@import '../../../vairables.scss';

.box {
  background: #353c45;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  padding: 15px;
  // margin: {
  //   bottom: 30px;
  //   right: 30px;
  // }
  border-radius: 2px;
  border-top: 4px solid;
  font: {
    size: 22px;
    variation-settings: 'wght' 900;
  }
  scroll-snap-align: center;
  min-width: calc(70vw - 30px);
  transition: all 300ms ease-in-out;

  &.deaths {
    border-top-color: $red;
  }
  &.confirmed {
    border-top-color: $orange;
  }
  &.recovered {
    border-top-color: $green;
  }
  &.tested {
    border-top-color: $blue;
  }
  &.active {
    border-top-color: $yellow;
  }
  &.critical {
    border-top-color: $purple;
  }
  .title {
    font: {
      size: 20px;
    }
    margin: {
      top: 0;
      bottom: 10px;
    }
    transition: font-size 300ms ease-in-out;
  }
  .total-number {
    margin: 0;
    margin-bottom: 15px;
    font: {
      size: 28px;
      variation-settings: 'wght' 900;
    }
    transition: font-size 300ms ease-in-out;
  }
  .today {
    font: {
      size: 18px;
      variation-settings: 'wght' 700;
    }
  }
  .per-mil {
    font: {
      size: 16px;
      variation-settings: 'wght' 500;
    }
  }
  p:last-of-type {
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 576px) {
  .box {
    min-width: calc(60vw - 30px);
  }
}
@media only screen and (min-width: 769px) {
  .box {
    flex-basis: calc(100% / 3 - 20px);
    min-width: initial;
    padding: 20px;
    // margin: {
    //   bottom: 20px;
    //   right: 20px;
    // }

    // &:nth-of-type(2n + 2) {
    //   margin-right: 0;
    // }
    // &:nth-of-type(5),
    // &:nth-of-type(6) {
    //   margin-bottom: 0;
    // }

    .total-number {
      font: {
        size: 32px;
      }
    }
    .today {
      font: {
        size: 18px;
      }
    }
  }
}
