$green: #4daa8b;
$blue: #4294c0;
$orange: #f3b166;
$red: #da5461;

$purple: #9b59b6;
$yellow: #f9df51;

$cases-total: #bb8f6a;
$deaths-total: #9d654e;
