.totals {
  & {
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.3);
    margin-top: -5px;
    padding: 20px;
    width: 100%;
    z-index: 6;
  }

  > .country-stats {
    width: 100%;
    margin: {
      top: 15px;
      bottom: 20px;
    }
    > h2 {
      font: {
        size: 36px;
        variation-settings: 'wght' 900;
      }
      margin: {
        bottom: 20px;
        top: 0;
      }
      span {
        font: {
          size: 30px;
          variation-settings: 'wght' 500;
        }
      }
      width: 100%;
    }
    > p {
      display: block;
      > strong {
        font: {
          size: 24px;
          variation-settings: 'wght' 900;
        }
      }
      &.pop {
        // margin-top: -5px;
      }
      &.updated {
        > span {
          display: block;
          font-size: 14px;
          margin: {
            top: 15px;
          }
        }
      }
    }
  }
  > .boxes {
    display: flex;
    flex-flow: row nowrap;
    gap: 30px;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
  }
}

@media only screen and (min-width: 576px) {
  .totals {
    & {
    }
  }
}

@media only screen and (min-width: 769px) {
  .totals {
    & {
      box-shadow: 3px -3px 3px rgba(0, 0, 0, 0.3);
      padding: 30px 20px;
      // max-width: 650px;
      width: 100%;
      min-width: 60%;

      > .boxes {
        flex-flow: row wrap;
        // flex-basis: calc(50% - 15px);
        // margin-bottom: 30px;
        // &:nth-last-of-type(2n) {
        //   margin-right: 30px;
        // }
      }
    }
  }
}

@media only screen and (min-width: 1025px) {
  .totals {
    min-width: 30%;
  }
}
