.not-found {
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 100px 0;
  text-align: center;
  height: calc(100vh - 230px);
  a {
    color: white;
  }
}

@media only screen and (min-width: 769px) {
  .not-found {
    height: calc(100vh - 180px);
  }
}
