footer {
  padding: 30px;
  .container {
    width: 200px;
    margin: 5px auto 0;
  }
  .jw-logo {
    position: relative;
    font-size: 25px;
    font-variation-settings: 'wght' 500;
    a {
      color: white;
      text-decoration: none;
      transition: all 300ms linear;
      &:hover {
        font-variation-settings: 'wght' 700;
      }
    }
    span {
      font-size: 16px;
      font-variation-settings: 'wght' 200;
      &:last-of-type {
        font-size: 20px;
        position: absolute;
        right: 5px;
        bottom: -20px;
      }
    }
  }
}
