*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  background: #262c33;
  color: white;
  font: {
    family: 'Spartan', sans-serif;
    size: 20px;
    variation-settings: 'wght' 400;
  }
  height: 100%;
  margin: 0;
  min-height: 100vh;
  padding: 0;
  width: 100vw;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
