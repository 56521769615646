header {
  & {
    align-items: center;
    background: #353c45;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    padding: 20px;
    position: sticky;
    top: 0;
    z-index: 10;
  }

  .project-title {
    font: {
      family: 'Spartan', sans-serif;
      variation-settings: 'wght' 300;
    }
  }
  h1 {
    font-size: 1.675rem;
    line-height: 1.25;
    margin: 0;
    span {
      font-variation-settings: 'wght' 700;
    }
    transition: font-size 300ms ease-in-out;
  }
  img {
    width: 2rem;
    height: 2rem;
    margin: {
      right: 10px;
      top: -2px;
    }
    transition: all 300ms ease-in-out;
  }

  a {
    color: white;
    text-decoration: none;
    display: flex;
  }

  .search {
    margin-top: 20px;
    width: 300px;
  }
}

@media only screen and (min-width: 576px) {
  header {
    & {
      align-content: center;
      justify-content: space-between;
      flex-flow: row;
      padding: 20px 30px;
    }

    .project-title {
      margin-bottom: 0;
    }

    .search {
      margin-top: 0;
    }
  }
}

@media only screen and (min-width: 768px) {
  header {
    h1 {
      font-size: 2.1875rem;
      line-height: 1;
      padding-top: 5px;
    }

    img {
      margin-top: 2px;
    }
  }
}
