.dashboard {
  & {
    min-height: calc(100vh - 160px);
  }
  > .total-container {
    display: flex;
    flex-flow: column;
    min-height: 200px;
    margin-bottom: 55px;
  }
  > .graph-container {
    display: flex;
    flex-flow: column;
    min-height: 200px;
    padding: 20px;
  }
  .half-graphs {
    display: flex;
    flex-flow: column;
  }
  > .sources {
    margin-top: -60px;
    padding: 20px;
  }
}

@media only screen and (min-width: 769px) {
  .dashboard {
    > .total-container {
      flex-flow: row;
    }
    .half-graphs {
      flex-flow: row;
    }
  }
}
